import React, { Component } from "react";
import {
  Modal,
  FormInput,
  FormSelect,
  Button,
  Dropzone,
  Loader,
} from "ui-kit-ck-consultant";

import axiosConfig from "../../../utils/axiosConfig";
import {
  getUsers,
  getAgencies,
  getGroups,
  getRegions,
} from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export default class ModalCompanyMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      companyOptions: [],
      groupOptions: [],
      regionOptions: [],
      agencyOptions: [],
      documentOptions: [],
      editorOptions: [],
      date: "",
      company: "DEFAULT",
      group: "DEFAULT",
      region: "DEFAULT",
      agency: "DEFAULT",
      document: "DEFAULT",
      editor: "DEFAULT",
      director: "",
      location: "",
      name: "",
      dataBlob: null,
      dataOriginName: null,
      dataMime: null,
      // Other
      alerts: [],
    };
    this.initialState = this.state;
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getGroups();
    this.getEditors();
    this.setState({
      editor: this.context.name,
    });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "group") {
          this.getRegions();
          this.getEditors();
        } else if (name === "region") {
          this.getAgencies();
          this.getEditors();
        }
      }
    );
  };

  handleValidClick = () => {
    if (
      (this.state.name &&
        this.state.date &&
        this.state.group &&
        this.state.region &&
        this.state.agency &&
        this.state.company) ||
      this.state.group !== "DEFAULT" ||
      this.state.region !== "DEFAULT" ||
      this.state.agency !== "DEFAULT"
    ) {
      this.setState({ isLoad: true }, () => {
        if (!this.props.isEdit) {
          if (
            (this.props.type === 1 &&
              this.state.dataBlob &&
              this.state.dataOriginName &&
              this.state.dataMime) ||
            (this.props.type !== 1 &&
              this.state.document &&
              this.state.editor &&
              this.state.director &&
              this.state.location &&
              this.state.editor !== "DEFAULT" &&
              this.state.director !== "DEFAULT")
          ) {
            axiosConfig("company-monitoring/insert", {
              data: this.getData(),
            }).then((res) => {
              if (res.data.success) {
                this.setState(this.initialState);
                this.props.onClose();
              }
            });
          } else {
            console.log(
              this.props.type === 1,
              this.state.dataBlob,
              this.state.dataOriginName,
              this.state.dataMime,
              this.props.type !== 1,
              this.state.document,
              this.state.editor,
              this.state.director,
              this.state.location,
              this.state.editor !== "DEFAULT",
              this.state.director !== "DEFAULT"
            );

            this.setState({ isLoad: false }, () =>
              alert("Une erreur est survenue")
            );
          }
        } else {
          let data = this.getData();
          data["id"] = this.props.id;
          axiosConfig("company-monitoring/update", {
            data: data,
          }).then((res) => {
            if (res.data.success) {
              this.setState({
                isLoad: false,
                name: "",
                date: "",
                company: "DEFAULT",
                group: "DEFAULT",
                region: "DEFAULT",
                agency: "DEFAULT",
              });
              this.props.onClose();
            }
          });
        }
      });
    }
  };

  getData() {
    return {
      name: this.state.name,
      date: this.state.date,
      company: this.state.company,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      document: this.state.document,
      editor: this.state.editor,
      director: this.state.director,
      location: this.state.location,
      base64: this.state.dataBlob,
      mime: this.state.dataMime,
      originName: this.state.dataOriginName,
      type: this.props.type,
    };
  }

  getGroups = () => {
    getGroups((groupOptions) => {
      if (
        this.context.role > 2 ||
        !this.context.group ||
        (this.state.group && this.state.group !== "DEFAULT")
      ) {
        this.setState({ groupOptions }, () => this.getRegions());
      } else {
        const groupList = [];
        groupList.push(
          groupOptions.find((element) => element.value === this.context.group)
        );
        this.setState(
          {
            groupOptions: groupList ? groupList : null,
          },
          () => this.getRegions()
        );
      }
    }, false);
  };

  getRegions = () => {
    getRegions(
      (regionOptions) => {
        this.setState(
          { regionOptions, region: this.state.region || "DEFAULT" },
          () => this.getAgencies()
        );
      },
      false,
      this.state.group !== "DEFAULT" ? this.state.group : this.context.group
    );
  };

  getAgencies = () => {
    getAgencies(
      (agencyOptions) => {
        this.setState({
          agencyOptions,
          agency: this.state.agency || "DEFAULT",
        });
      },
      true,
      this.state.region !== "DEFAULT" ? this.state.region : this.context.region
    );
  };

  getEditors() {
    getUsers(
      this.context.network,
      this.context.group,
      this.context.region,
      null,
      null,
      null,
      (editorOptions) => this.setState({ editorOptions })
    );
  }

  getDocuments() {
    axiosConfig("company-monitoring/get-documents", {
      data: {
        type: this.props.type,
      },
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({
          document: "DEFAULT",
          documentOptions: res.data.data,
        });
      } else {
        this.setState({
          document: "DEFAULT",
          documentOptions: [],
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show === true &&
      this.props.isEdit === true &&
      this.props.isEdit !== prevProps.isEdit
    ) {
      axiosConfig("company-monitoring/get", {
        data: { id: this.props.id },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState(
            {
              date: res.data.data.date,
              company: res.data.data.company
                ? res.data.data.company
                : "DEFAULT",
              name: res.data.data.name,
            },
            () => {
              this.getGroups();
              this.getEditors();
            }
          );
        }
      });
    }

    if (
      this.props.show === true &&
      this.props.isEdit === false &&
      this.props.show !== prevProps.show
    ) {
      this.getDocuments();
      this.getGroups();
      this.getRegions();
      this.getAgencies();
    }
  }

  blobValue = (e) => {
    this.setState({
      dataBlob: e.base64,
      dataMime: e.mime,
      dataOriginName: e.name,
    });
  };

  render() {
    let companyOptions = [...this.context.companyOptions];
    companyOptions.splice(0, 1);

    return (
      <>
        <Modal
          show={this.props.show}
          onClose={() => {
            this.setState(this.initialState);
            this.props.onClose();
          }}
          title={
            !this.props.isEdit
              ? `Ajouter ${this.props.name}`
              : `Modifier ${this.props.name}`
          }
        >
          <FormInput
            className={"my-10"}
            type="date"
            placeholder="Date"
            title="Date"
            name="date"
            value={this.state.date}
            onChange={this.handleInputChange}
          />
          <FormSelect
            className={"my-10"}
            title="Compagnie"
            init="Veuillez sélectionner une compagnie"
            name="company"
            ignore={true}
            value={this.state.company}
            options={companyOptions}
            onChange={this.handleInputChange}
          />
          {this.props.isEdit ? (
            <p className="red">
              Lors de la modification il faut redéfinir le groupe / la région /
              l'agence
            </p>
          ) : null}
          <FormSelect
            className={"my-10"}
            title="Groupe"
            init="Veuillez sélectionner un groupe"
            name="group"
            ignore={true}
            value={this.state.group}
            options={[
              { value: "", text: "Tous les groupes" },
              ...this.state.groupOptions,
            ]}
            onChange={this.handleInputChange}
          />
          <FormSelect
            className={"my-10"}
            title="Région"
            init="Veuillez sélectionner une région"
            name="region"
            ignore={true}
            value={this.state.region}
            options={[
              { value: "", text: "Toutes les régions" },
              ...this.state.regionOptions,
            ]}
            onChange={this.handleInputChange}
            disabled={this.state.group === "DEFAULT"}
          />
          <FormSelect
            className={"my-10"}
            title="Agence"
            init="Veuillez sélectionner une agence"
            name="agency"
            ignore={true}
            value={this.state.agency}
            options={[...this.state.agencyOptions]}
            onChange={this.handleInputChange}
            disabled={this.state.region === "DEFAULT"}
          />
          {this.props.isEdit === false && this.props.type !== 1 ? (
            <FormSelect
              className={"my-10"}
              title="Modèle"
              init="Veuillez sélectionner un modèle"
              name="document"
              ignore={true}
              value={this.state.document}
              options={this.state.documentOptions}
              onChange={this.handleInputChange}
            />
          ) : null}
          {this.props.isEdit === false && this.props.type !== 1 ? (
            <FormSelect
              className={"my-10"}
              title="Éditeur"
              init="Veuillez sélectionner un éditeur"
              name="editor"
              ignore={true}
              value={this.state.editor}
              options={this.state.editorOptions}
              onChange={this.handleInputChange}
            />
          ) : null}
          {this.props.isEdit === false && this.props.type !== 1 ? (
            <FormInput
              className={"my-10"}
              type="text"
              placeholder="Dirigé par"
              title="Dirigé par"
              name="director"
              value={this.state.director}
              onChange={this.handleInputChange}
            />
          ) : null}
          {this.props.isEdit === false && this.props.type !== 1 ? (
            <FormInput
              className={"my-10"}
              type="text"
              placeholder="Lieu"
              title="Lieu"
              name="location"
              value={this.state.location}
              onChange={this.handleInputChange}
            />
          ) : null}
          <FormInput
            className={"my-10"}
            type="text"
            placeholder="Nom"
            title="Nom"
            name="name"
            value={this.state.name}
            onChange={this.handleInputChange}
          />
          {this.props.isEdit === false && this.props.type === 1 ? (
            <Dropzone
              className={"m-auto my-10"}
              dropHandler={this.blobValue}
              typeName={[
                "docx",
                "doc",
                "xls",
                "xlsx",
                "cdv",
                "ppt",
                "pdf",
                "odt",
                "jpeg",
                "png",
                "mp4",
              ]}
              typeAccept={[
                "application/vnd.ms-powerpoint",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sh",
                "application/pdf",
                "image/jpeg",
                "video/mp4",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.sheet",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.openxmlformats-officedocument.wordprocessingm",
                "application/msword",
                "application/vnd.oasis.opendocument.text",
                "image/png",
                "text/csv",
              ]}
            />
          ) : null}
          <Button
            className={"my-10"}
            text="Valider"
            onClick={() => this.handleValidClick()}
          />
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
