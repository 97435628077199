import React from "react";
import {
  Card,
  BarChart,
  FormCheckbox,
  Table,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
  TabsCustomItem,
  TabsCustom,
  TabsCustomMenu,
  TabsCustomHeader,
  TabsCustomBody,
} from "ui-kit-ck-consultant";

import { getColors } from "../../../utils/colors";

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPercentage: true,
      tabsIndex: 0,
      tabsMenu: ["Graphique", "Tableau"],
    };
  }

  render() {
    const colors = getColors();

    return (
      <>
        <Card className="mb-10 mb-20-xl min-height-0">
          <FormCheckbox
            text={"Affichage pourcentage"}
            checked={this.state.isPercentage}
            onChange={({ target }) =>
              this.setState({ isPercentage: target.checked })
            }
          />
        </Card>
        {this.props.data.hasOwnProperty("filters")
          ? this.props.data.filters.map((filter, idx) => (
              <Card className="mb-10 mb-20-xl overflow-x-auto" key={idx}>
                <TabsCustom>
                  <TabsCustomHeader noCard>
                    {this.state.tabsMenu.map((element, idx) => (
                      <TabsCustomMenu
                        key={idx}
                        title={element}
                        active={this.state.tabsIndex === idx}
                        onClick={() =>
                          this.setState({
                            tabsIndex: idx,
                          })
                        }
                      />
                    ))}
                  </TabsCustomHeader>
                  <TabsCustomBody noCard>
                    <TabsCustomItem active={this.state.tabsIndex === 0}>
                      <BarChart
                        format={this.state.isPercentage ? "percentage" : ""}
                        digits={this.state.isPercentage ? 2 : 0}
                        title={filter.name}
                        labels={[...this.props.data.labels]
                          .slice(0, 90)
                          .reverse()}
                        dataSet={Object.keys(this.props.data.regions)
                          .reverse()
                          .map((key, idxRegion) => ({
                            type: key === "Toutes les régions" ? "bar" : "line",
                            yAxisID: key === "Toutes les régions" ? "A" : "B",
                            label: key,
                            data: this.props.data.regions[key][
                              this.state.isPercentage ? `p${idx}` : `f${idx}`
                            ]
                              .slice(0, 90)
                              .reverse(),
                            color:
                              key === "Toutes les régions"
                                ? "rgba(120, 12, 70, 0.4)"
                                : colors[idxRegion],
                          }))}
                        height={300}
                        xAxes={[
                          {
                            gridLines: {
                              display: false,
                            },
                          },
                        ]}
                        yAxes={[
                          {
                            id: "A",
                            ticks: {
                              display: false,
                            },
                          },
                          {
                            id: "B",
                            ticks: {
                              display: false,
                            },
                          },
                        ]}
                      />
                    </TabsCustomItem>
                    <TabsCustomItem active={this.state.tabsIndex === 1}>
                      <Table isCSV>
                        <Thead>
                          <Tr>
                            <Th>Numéro de dossier</Th>
                            <Th>Numéro sinistre</Th>
                            <Th>Date réception mission</Th>
                            <Th>Région</Th>
                            <Th>Agence</Th>
                            <Th>Expert</Th>
                            <Th>Assistant(e)</Th>
                            <Th>Code état</Th>
                            <Th>Nature expertise</Th>
                            <Th>Total HT</Th>
                            <Th>VRADE</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {this.props.data.regions["Toutes les régions"][
                            `folders_${idx}`
                          ]
                            .filter((folder) => folder.dateClosing)
                            .map((folder, idx) => (
                              <Tr key={idx}>
                                <Td>{folder.reference}</Td>
                                <Td>{folder.sinister}</Td>
                                <Td>{folder.dateMission}</Td>
                                <Td>{folder.region}</Td>
                                <Td>{folder.agency}</Td>
                                <Td>{folder.expert}</Td>
                                <Td>{folder.assistant}</Td>
                                <Td>{folder.code}</Td>
                                <Td>{folder.natureExpertise}</Td>
                                <Td>{folder.totalReport}</Td>
                                <Td>{folder.VRADE}</Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </TabsCustomItem>
                  </TabsCustomBody>
                </TabsCustom>
              </Card>
            ))
          : null}
      </>
    );
  }
}
